import { fn } from "../api";

const html = `<div id="output">
<div class="hero">
  <div class="hero-infographic">
    <div class="hero-number">
      <h3 class="hero-number-header">40,000</h3>
      <p class="sub-hero">species at risk of extinction</p>
    </div>
  </div>
</div>
<div class="cards">
  <div class="card">
    <h4 class="card-title">deforestation </h4>
    <p class="card-text"></p>
    <p class="card-text"> 90% of deep forestation are caused by agrucultural expasinion.  This means that the organism won't have a home to live becuase their home will be desotry from agrcutrual expasinion</p>
    <a  href="https://standfortrees.org/blog/deforestation-effects-on-animals/" target="_blank">LEARN MORE</a>
  </div>
  <div class="card">
    <h4 class="card-title">climate change </h4>
    <p class="card-text"> climate change has cause the temperture to sky rocket up. The animals wont have a great chance surival because they haven't adapted to the new environment.</p>
    <p class="card-text"></p>
    <p class="card-text">.</p>
    <a href="https://tinyurl.com/un-sdg-11" target="_blank">LEARN MORE</a>
  </div>
  <div class="card">
    <h4 class="card-title">overhunting</h4>
    <p class="card-text">people will go there way to harm and even Kill these animals. if this continte over and over  the species will no longer be lving  in earth  </p>
    <p class="card-text"></p>
    <p class="card-text"></p>
    <p class="card-text"></p>
    <a href="https://sdgs.un.org/goals/goal15" target="https://www.conserve-energy-future.com/causes-effects-solutions-overhunting.php">LEARN MORE</a>
  </div>
</div>
<div class="subscribe">
  <div class="subscribe-title"> <input type="text" placeholder="Enter your email" class="email"><button value="test" class="submit" onclick="open_link()">Learn More</button><button value="test" class="submit" onclick="open_link()">Pledge an hour</button></div>
</div>
</div>`;

const css = `.email {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}

.card {
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color:lightgray;
  margin-right: 10px;
  margin-left: 10px;
  width: 300px;
  box-shadow: 10px 10px 10px #c0c0c0;
}

.card-title {
  margin-bottom: 20px;
  font-size: 20px;
}
.submit {
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}
.cards {
  margin-top: 40px;
  width: 800px;
  justify-content: space-between;
  display: flex;
  margin: 40px auto;    
}

.hero-infographic {
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  color: white;
}


body {
  align-items: center;
  flex-direction: column;
  display: flex;
  font-family: Trebuchet MS, sans-serif;
  width: 100vw;
}
.subscribe-title {
  margin-bottom: 20px;
  font-size: 20px;
}
.subscribe {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}
.hero-number {
  font-size: 72px;
  padding: 0;
  
}
.hero {
  background-image: url(https://1.bp.blogspot.com/-wnk7rTwHyw4/WMsQFrCmGII/AAAAAAAAUgs/gvqzgIUsYokLcnta_PNm51fVuqpYa8dxwCLcB/s1600/BornInChina5890f4e9da79d.jpg);
  background-color: rgba(0,0,0,0.5);
  background-blend-mode: multiply;  
  justify-content: center;
  align-items: flex-start;
  box-shadow: 10px 10px 10px #c0c0c0;
  border-radius: 5px;
  display: flex;
  background-size: cover;
  padding: 20px;
  background-position:center;
  width: 100vw;
  padding-top:200px;
  padding-bottom :200px 
    
}
.sub-hero {
  font-size: 33px;
  color:white 
}

.hero-number-header{
  margin: 0
}
`;

const js = `function link(e) {
  window.open(e,"_blank")}
  
  // Describe this function...
  function open_link() {
    link("https://www.un.org/sustainabledevelopment/energy/");}
  `;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
