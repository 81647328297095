export const fn = `function postItem(sdg) {
  const input = document.getElementById("nameInput");
  const value = input.value;

  fetch("https://2687ii4nwd.execute-api.us-east-1.amazonaws.com/datastore_api")
    .then((res) => res.json())
    .then((res) => {
      const items = res?.Items;
      const highestId = items.reduce((acc, item) => {
        if (item.Id > acc) {
          return item.Id;
        }
        return acc;
      }, 0);

      const Id = highestId + 1;

      fetch(
        "https://2687ii4nwd.execute-api.us-east-1.amazonaws.com/datastore-post",
        {
          method: "POST",
          body: JSON.stringify({
            Id: Id.toString(),
            name: value,
            sdg,
          }),
        }
      );
    });
};`;
