const html = `<head>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
</style>
</head>

<body>
<div class='header'>

</div>

<div class='hero'>
  <h1 class='no-margin center-text text'>Climate Action</h1>
  <p class='no-margin center-text text caption'>The U.S used over 90 exajoules in 2022, making us the second largest consumer of electricity in the world. Globally we used 490 exajoules in 2021.</p>
</div>
<h2>
  <div class='solution'>
    <h2>
      <div class='solution'>What is Climate Change?</div>
    </h2>
  </div>
</h2>
<div class='climate'>Climate Change is shifts in temperature, and weather. Examples of this happening is longer, and more harsh drought periods, and glaciers and sea ice melting, in areas that they normally shouldn't.
<div class="card-container">
  <div class="card">
    <h2 class='joule text black-text'>What is a Joule?</h2></br>

    <p class='jDesc'>A joule is a unit of energy that describes the amount of energy used by a newton to to do work, or make heat.</p>
  </div>
  <div class="card two">
    <h2 class='joule text black-text exajoule'>What is a Exajoule?</h2></br>
    <p class='jDesc exjDesc'>A unit of energy that is equal to 10^18 of one joule(a lot). Bloomerg.com states, "one exajoule is the equivalent of 24 million tons of oil." </p>
  </div>
  <div class="card">
    <h2 class='joule text black-text'>How Much is Used in a Day?</h2></br>
    <p class='joule text black-text jDesc'>The average amount of energy used in a day globally is 104,400,000 joules.</p>
  </div>
</div>
</br>
<h2>
  <div class='problem'>How using Fossil Fuels Hurt the Earth</div>
</h2>
<h3>
  <div class='fossil text black-text'>Burning Fossils release large amounts of carbon dioxide, and greenhouse gas into the air, polluting it. We are rapidly burning fossil fuels buy using cars and other everyday actions. Also therre is a constant demand for cars that use fossil fuels so there is car companies mass produce them, feeding into the problem. Fossil Fuels is a limited source, meaning that we will eventually run out of Fossil Fuels, and we would have to switch to a substitute for energy.
</h3>
</div>
<h2>
  <div class='solution'>What Can We Do?</div>
</h2>
<h3>
  <div class='fossil text black-text'>In order for us to prevent our planet from becoming too polluted, we have all have to work together. One person can fix this, and if everyone contributed it would would take sometime. But personally, the next time you go somewhere, ride a CitiBike, or limit your use of different items in your house that burn carbon dioxide.
</h3>
</div>
<div>
  <h2 class='fact'>What Have We Done?
</div>
</h2>
<h3>
  <div class='fossil text black-text'>Scientists have found a way to produce energy without releasing carbon-dioxide, using the same energy that powers the sun. Although it is difficult to control because of it's extrememly high temperature, it could give us a energy soure that would be cheaper because of it is more renewable than fossil fuels.
</h3>
</div>
<div class='click'>
  <div class='click-title'>
    <button value='test' class='submit' type="button" onClick="do_something()">Visit the SDG website</button><button value="test" class='submit' type="button" onClick="do_something2()">Learn More About Fusion Energy</button>
    <button value="test" class='submit' type="button" onClick="do_something3()">Learn About Other SDG's</button>`;

const css = `
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
div {
    background-color: white;
  }
  
  .text {
    color: white;
    font-family: "Oswald", sans-serif;
    font-size: 3.6rem;
  }
  /* h2{
    border-style: dashed;
    border-width: 2px;
    font-size: 100px;
  } */
  
  .hero {
    background-image: url("https://static01.nyt.com/images/2022/03/01/business/01economy-briefing-germanautos/01economy-briefing-germanautos-videoSixteenByNine3000.jpg");
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 50px 10px;
    /*   filter: brightness(50%); */
    background-color: rgba(0, 0, 0, 0.75);
    background-blend-mode: multiply;
  }
  .no-margin {
    margin: 0;
  }
  .center-text {
    text-align: center;
  }
  .caption {
    top: 70px;
    left: calc(50% - 167px);
    font-size: 1.3rem;
  }
  .joule {
    border-width: 3px;
  /*   border-style: solid; */
    border-radius: 10%;
    width: 150px;
    font-size: 24px;
    position: relative;
    left: 130px;
    border-style: inset;
    border-color: gray;
  }
  .black-text {
    color: black;
  }
  .jDesc {
    bottom: 60px;
    border-style: inset;
    border-radius: 10%;
    height: fit-content;
    font-size: 20px;
    position: relative;
    left: 130px;
    height: 200px;
    max-width: 150px;
    overflow: none;
    word-wrap: break-word;
    font-family: "Oswald", sans-serif;
    font-size: 17.9px;
    text-align: center;
    border-color: gray;
  }
   .exajoule{ 
    text-align: center;
    border-width: 3px;
  /*   border-style: solid; */
    border-radius: 10%;
    width: 150px;
    font-size: 24px;
  /*   position: relative;  */
    border-style: inset;
    border-color: gray;
     
  }
  .card{
    margin: 5px;
    text-align: center;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    width: 76%;
  }
  .exjDesc{
   text-align: center;
    border-height: 210px;
  }
  .problem{
     font-family: "Oswald", sans-serif;
    text-align: center;
    justify-content: center;
  }
  .fossil{
     border-width: fit-content;
  /*   border-style: solid; */
    border-radius: 10%;
  
    font-size: 22px;
    position: relative;
    left: 10px;
    border-style: inset;
    border-color: gray;
    text-align: center;
  }
  .button{
     font-family: "Oswald", sans-serif;
    : 100px;
  }
  .solution{
      font-family: "Oswald", sans-serif;
    text-align: center;
    justify-content: center;
  }
  .fact{
    font-family: "Oswald", sans-serif;
    text-align: center;
    justify-content: center;
  }
  .click{
  justify-content: center;
    display: flex;
    margin-top: 40px;
  }
  .submit{
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #cccccc;
  }
  .climate{
    font-family: "Oswald", sans-serif;
    text-align: center;
    font-size: 20px;
  }`;

const js = `function link(e){
        window.open(e,"_blank");
      }
     
     function do_something(){ link("https://sdgs.un.org/goals/goal13")
     }
     function do_something2(){ 
     link("https://www.pbs.org/newshour/science/watch-live-energy-department-announces-major-breakthrough-in-fusion-energy-research#:~:text=WASHINGTON%20(AP)%20%E2%80%94%20Scientists%20announced,process%20that%20powers%20the%20sun.")
     }
     function do_something3(){link('https://sdgs.un.org/goals')}`;

export const project = `
     <html>
         <head>
             <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
         </head>
         <body>
             ${html}
             <script type="text/javascript">
               ${js}
             </script>
         </body>
     </html>
     `;
