import { fn } from "../api";

const html = `<div class="nav">
<div class="button">
  <button class="dropbtn" onmouseover="hover()" onmouseleave="leave()">More Info</button>
  <div class="dropdown-content" onmouseover="hover()" onmouseleave="leave()">
    <a onclick=" link('https://sdgs.un.org/goals')">17 SDGs</a>
    <a onclick=" link('https://en.wikipedia.org/wiki/Sustainable_Development_Goals')">About SDGs</a>
    <a onclick="                link('https://sdgs.un.org/topics/oceans-and-seas')"> About LBW</a>

  </div>
</div>
</div>
<div id="output">
<div class="hero">
  <h1 class="header">What is life like Below Water?</h1>
  <div class="QA">
    <p class="Q&amp;A">About 52% of marine life has disappeared in the last 40 years.</p>
  </div>
</div>
<div class="cards-container">
  <div class="Cards">
    <div class="card">
      <h2 class="card-title">Problems</h2>
      <p class="card-text">1: Marine Pollution</p>
      <p class="card-text">2: Over-Fishing</p>
      <p class="card-text">3: Acidification </p>
      <p class="card-text">4: Eutrophication</p>
      <p class="card-text">5: Ocean Warming</p>
    </div>
    <div class="card">
      <h2 class="card-title">Purpose</h2>
      <p class="card-text">Sustainably manage and protect marine and coastal ecosystems to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration in order to achieve healthy and productive oceans.</p>
    </div>
    <div class="card">
      <h2 class="card-title">Solutions</h2>
      <p class="card-text">1: Reduce Marine Pollution</p>
      <p class="card-text">2: Protect and restore ecosystems</p>
      <p class="card-text">3: Conserve coastal and marine areas</p>
      <p class="card-text">4: Sustainable Fishing</p>
      <p class="card-text">5: Conserve coastal and marine areas</p>
      <p class="card-text">6: End subsides contributing to overfishing</p>
      <p class="card-text">7: Increase the economic benefits from sustainable use of marine resources</p>
      <p class="card-text">8: Increase scientific knowledge, research and technology for ocean health</p>
    </div>
  </div>
</div>
<div class="click">
  <div class="click-title"> <button value="test" class="submit" onclick="do_something()">More Info(Problems)</button><button value="test" class="submit" onclick="do_something2()">More Info(Solutions)</button><button value="test" class="submit" onclick="do_something3()">More Info(Statistics) </button></div>
</div>
</div>`;

const css = `.nav {
  display: flex;
  align-items: center;
  background-color: black;
  padding: px;
  justify-content: flex-end;
}
.dropbtn {
  background-color: black;
  color: white;
  width: 90px;
  height: 80px
}
.hero {
  text-align: center;
  font-size: 30px;
  font-family: Tahoma, sans-serif;
  padding: 20px;
  background-size: cover;
  background-image: url(https://images.squarespace-cdn.com/content/v1/5f5a30614e872e3a91f2ec7f/1629913428389-9PG26WJDLU1SGFZROWJZ/AdobeStock_321027086.jpeg?format=1000w);
  height: 430px;
  color: #ffffff;
}
.QA {
  text-align: center;
  font-size: 30px;
  font-family: Verdana, sans-serif;
}
.cards-container {
  display: flex;
  justify-content: center;
}
.Cards {
  margin-top: 40px;
  width: 800px;
  justify-content: space-between;
  display: flex;
}
.card {
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #00BFFF;
  margin-right: 10px;
  margin-left: 10px;
  width: 300px;
  box-shadow: 10px 10px 10px #87CEFA;
}
.click-title {
  margin-bottom: 20px;
  font-size: 20px;
}
.submit {
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #cccccc;
}
.click {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown-content:hover .dropbtn {background-color: blue;}

.button {
  position: relative;
  display: inline-block;
}

/* .dropbtn:hover {
  background-color: blue;
} */
.dropbtn:hover  {
  display: block !important;
  background-color: blue;
}



`;

const js = `function link(e) {
  window.open(e, "_blank");
}

// Describe this function...
function do_something() {
  link("https://sdgs.un.org/goals/goal14");
}

// Describe this function...
function do_something2() {
  link("https://www.globalgoals.org/goals/14-life-below-water/");
}

function do_something3() {
  link("https://tinyurl.com/statisticsLBW");
}
function hover(){
  var dropdown = document.querySelector('.dropdown-content');
  dropdown.style.display = 'block';
  
  var button = document.querySelector('.dropbtn');
  button.style.backgroundColor='blue';
}


function leave(){
  var dropdown = document.querySelector('.dropdown-content');
  dropdown.style.display = 'none';
  
  var button = document.querySelector('.dropbtn');
  button.style.backgroundColor='black';
}`;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
