import { fn } from "../api";

const html = `
  <div>
    <div class="topnav">
      <a class="active float-left title" href="#home">
        <img
          src="https://www.globalgiving.org/img/sdg/15-life-on-land-icon.png"
          alt=""
        />
        <span>Life on Land</span>
      </a>
      <div>
        <a class="float-right" href="#home">
          About
        </a>
        <a class="float-right" href="#news">
          Learn
        </a>
        <a class="float-right" href="#about">
          Home
        </a>
      </div>
    </div>
    <div id="output">
      <div class="hero">
        <div class="hero-infographic">
          <div class="hero-number">
            <h3 class="hero-number-header">10,000,000</h3>
          </div>
        </div>
        <div class="info">
          <p class="info">Hectares of forest are destroyed every year </p>
        </div>
      </div>

      <div class="cards">
        <div class="card">
          <h4 class="card-title">Why should I care?</h4>

          <p class="card-text">
            Forests are a prominent aspect of human life everyday from the air
            we breathe to the water we drink.
          </p>
          <p class="card-text">
            Around 1.6 billion people depend on forests for their livelihood.
          </p>
          <p class="card-text">
            With fewer trees around to take in the carbon dioxide, this
            greenhouse gas accumulates in the atmosphere and accelerates global
            warming.
          </p>
        </div>
        <div class="card">
          <h4 class="card-title"> Who is being affected?</h4>
          <p class="card-text">
            Around 40 million species are expected to be at risk of extinction
            in a few decades.
          </p>
          <p class="card-text">
            80% of Earth's land animals and plants live in forests, and
            deforestation threatens species such as the Orangutans, Bengal
            tigers and the wide variety of birds.
          </p>
        </div>
        <div class="card">
          <h4 class="card-title">How can I help?</h4>
          <p class="card-text">Pledge an hour</p>
          <p class="card-text">
            Buy certified wood products. Read the labels and look for the FSC
            (Forest Stewardship Council) mark.
          </p>
          <p class="card-text">
            Buy recycled products and recylce them after use.
          </p>
        </div>
      </div>

      <div class="HowtoHelp">
        <h2>Learn More</h2>
        <div class="Deforestationmap">
          <a
            class="Imagelink"
            target="_blank"
            href="https://www.globalforestwatch.org/map/?map=eyJjZW50ZXIiOnsibGF0IjotMTcuMjA0MjA3MjQ3NzIwNTIsImxuZyI6LTI2LjY2OTk2OTg4NDAyODQ1OH19&mapMenu=eyJkYXRhc2V0Q2F0ZWdvcnkiOiJmb3Jlc3RDaGFuZ2UifQ%3D%3D&mapPrompts=eyJvcGVuIjp0cnVlLCJzdGVwc0tleSI6InN1YnNjcmliZVRvQXJlYSJ9"
          >
            <img
              src="https://s15.postimg.cc/rxhx9uaqz/GFW_new_data_layer_right.jpg"
              alt=""
            />
          </a>
        </div>

        <div class="subscribe">
          <div class="subscribe-title">
            <input
              type="text"
              placeholder="Enter your name"
              class="email"
              id="nameInput"
            />
            <button value="test" class="submit" onclick="open_link()">
              Learn More
            </button>
            <button value="test" class="submit" onclick="postItem('Preventing deforestation');open_link()">
              Pledge an hour
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>`;

const css = `
.topnav {
    background-color: black;
    overflow: hidden;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 30px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    
  }
  
  .Imagelink{
    cursor:pointer;
  }
  
  .hero{
    background-image: url(https://grist.org/wp-content/uploads/2021/11/palm-oil-deforestation.jpg);
  }
  
  .title {
    font-size: 40px !important;
  }
  
  .title img {
    width: 45px;
  }
  
  .topnav a:hover {
    background-color: black;
  }
  .topnav a.active {
    color: white;
  }
  
  .HowtoHelp {
    background-color: ccccc;
    height: 500px;
    text-align: center;
    font-size: 40px;
    padding-top: 10px;
    width: 100%;
  }
  
  h2 {
    text-align: center;
  }
  
  .email {
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  display: block;
  body {
    align-items: center;
    flex-direction: column;
    display: flex;
    font-family: Trebuchet MS, sans-serif;
    height: 45px;
  }
  .info {
    text-align: center;
    color: white;
    font-size: 55px;
    height: 100px;
  }
  .card-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .subscribe-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .cards {
    margin-top: 15px;
    width: 800px;
    justify-content: space-between;
    display: flex;
  }
  .card {
    margin-top: 80px;
    margin-bottom: 40px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #a2bd9e;
    margin-right: 80px;
    margin-left: 80px;
    width: 400px;
    box-shadow: 10px 10px 10px #c0c0c0;
    height: 350px;
    font-size: 20px;
  }
  .submit {
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .hero-number {
    font-size: 72px;
    color: white;
    text-align: center;
  }
  .subscribe {
    justify-content: center;
    display: flex;
    margin-top: 40px;
  }
  .hero {
    padding: 20px;
    background-size: cover;
    background-repeat: none;
    background-position: center;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: block;
    background-color: #a2bd9e;
    height: 650px;
    box-shadow: 10px 10px 10px #c0c0c0;
    padding-top: 300px;
    padding-bottom: 300px;
  }
  
  .cards {
    background-color: #d9d9d9;
    width: 100%;
  }
  
  body {
    width: 100vw;
    margin: 0;
  }
  
  .hero-number-header {
    font-size: 10rem;
    margin: 0;
  }
  
  .info {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .second-cards {
    background-color: inherit;
    font-size: 20px;
  }
  .Deforestationmap{
    height:400px;
    align-items:center;
    border-radius:.5;
    
  }
`;

const js = `function link(e) {
    window.open(e,"_blank")}
    
    // Describe this function...
    function open_link() {
      link("https://www.un.org/sustainabledevelopment/energy/");}
    `;

export const project = `
    <html>
        <head>
            <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
        </head>
        <body>
            ${html}
            <script type="text/javascript">
              ${js}
              ${fn}
            </script>
        </body>
    </html>
    `;
