import { fn } from "../api";

const html = `<div id="output">
<div class="navbar">
  <div class="nav-holder">
    <div class="nav-left">
      <h1 class="class">Climate Action</h1>
    </div>
    <div class="nav-right"> <button value="test" class="nav-btn" onclick="open_link()">More Info</button></div>
  </div>
</div>
<div class="hero">
  <div class="hero-infographic">
    <div class="hero-number">
      <h3 class="hero-number-header">$3,640,000,000,000</h3>
      <span class="hero-subtext">in losses due to climate change</span>
    </div>
    
  </div>
</div>

<div class="section-btn-img">
  <p class="section-img-text">Click a button to see an image</p>
  
  <div class="btn-img-holder">
    <div class=""> <button value="test" class="img-btn1" onclick="show_img_water()">Water</button></div>
    <div class=""> <button value="test" class="img-btn2" onclick="show_img_snow()">Snow</button></div>
    <div class=""> <button value="test" class="img-btn3" onclick="show_img_fire()">Fire</button></div>
    <div class=""> <button value="test" class="img-btn4" onclick="show_img_air()">Air</button></div>
  </div>
  <div class="show-img"></div>
</div>
<div class="cards">
  <div class="card">
    <h4 class="card-title">Water</h4>
    <p class="card-text">Water,Water is getting higher every day and thats bad because people live in low places if it gets higher it will flood places that are low also the coral reef is also dieing and this is where animal lives and they can die because of this.</p>
    <p class="card-text">Water is realy important to the eviromant even the ones on land but sometime water get into some probloms and can be a monstor.</p>
  </div>
  <div class="card">
    <h4 class="card-title">Snow </h4>
    <p class="card-text">Snow,Snow is makeing places more cold and killing people a example in buffalo the snow was about 50 feet tall and people got suck in there cars and started getting too cold and died also it coverd houses and thing were bracking and there was no heat and this was a problom.</p>
    <p class="card-text">Snow is fun and all but it can be a real problome sometimes like it can be foot tall and with more snow the more cold it is and poeple can die because of that and we dont want that.</p>
    
  </div>
  <div class="card">
    <h4 class="card-title">Fire </h4>
    <p class="card-text">Fire, Fire is a masive problom it makes forest fire and this is bad because some animals live in the forest and these animals are important to the world another reson is house fires this is worst this burns and can spreed around more houses and kill people.</p>
    <p class="card-text">Fire is helpful because it cooks your food it makes you warm and it burns thing (。_。) it can brun wood and thats bad because wood has o2 and we need o2 to live.</p>
    
  </div>
  <div class="card">
    <h4 class="card-title">Impact</h4>
    <p class="card-text">Air, Air has been strong and this is danger beacuse wind is powerful and wind can pull things out and move things and lets say that wind is strong today and your walking and you look up and just see some random thing flying.</p>
    <p class="card-text">Air we need it to live with out is we would be dead and thats just bad human population would be 0 in 1 mil a second.</p> 
  </div>
</div>
<div class="suggestion">
  <div class="suggestion-title"> <input type="text" placeholder="Enter your suggestion" class="input-suggestion"><button value="test" class="btn-submit-suggestion" onclick="">Submit Suggestion</button><button value="test" class="btn-learn-more-3" onclick="open_link()">Learn More</button></div>
</div>
<div class="subscribe">
  <div class="subscribe-title"> <input type="text" placeholder="Enter your email" class="email" id="nameInput">
          <button value="test" class="submit" onclick="postItem('Climate Action');">Pledge an hour</button>
    <button value="test" class="submit" onclick="open_link()">Info</button>
</div>
</div>
</div>`;

const css = `.navbar {
  background: lightgray;
  padding-right: 50px;
  padding-left: 50px;
  }
  
  .hero-subtext {
    color: white;
    font-size: 24px;
    text-align: center;margin-left: 160px;
    
  }
  
  .nav-holder {
  display: flex;
  justify-content: space-between;  
  align-items: center;
  }
  
  .nav-left {
    
  }
  
  .nav-btn {
    width: 200px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid gray;
    background: lightblue;
    font-size: 14px;
    font-weight: 600;
  }
  
  .hero {
    object-fit: contain;
  width: 100%;
    height: 350px;
  margin-left: auto;
   margin-right: auto;
    background-image: url(https://www.noaa.gov/sites/default/files/styles/landscape_width_1275/public/2022-03/PHOTO-Climate-Collage-Diagonal-Design-NOAA-Communications-NO-NOAA-Logo.jpg);
    background-repeat:no-repeat;
  background-position: center center;
  }
  
  .hero-infographic {
  margin-left: auto;
  margin-right: auto;
    width: 750px;
    padding: 50px;
  }
  
  .hero-number-header {
      font-size: 72px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0px;
    color: white;
    text-align: center;
  }
  
  .hero-number {
    padding: 50px;
    background-color: gray;
    opacity: 75%;
  }
  
  .section-btn-img {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .section-img-text {
    font-size: 24px;
    text-align: center;
  }
  
  .btn-img-holder {
    display: flex;
    justify-content: space-between
  }
  
  .img-btn1 {
    background-color: blue;
    color: white;
    height: 35px;
    width: 75px;
  }
  
  .img-btn2 {
    background-color: white;
    height: 35px;
    width: 75px;
  }
  
  .img-btn3 {
    background-color: red;
    height: 35px;
    width: 75px;
  }
  
  .img-btn4 {
    background-color: gray;
    height: 35px;
    width: 75px;
  }
  
  .cards {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-evenly;
  }
  
  .card {
    border: 1px solid lightgray;
    margin-left: 25px;
    margin-right: 25px;
    padding: 10px;
    width: 300px;
    }
   
  .suggestion {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-evenly;
  }
  
  .subscribe {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-evenly;
  }
  
  .input-suggestion {
    width: 200px;
  }
  
  .show-img {
    display: none;
    width: 400px;
    height: 400px;
    margin: 20px;
  }
  
  .show-img {
    background-repeat: no-repeat;
    background-size: cover;
  }`;

const js = `function link(e) {
  window.open(e,"_blank")}
  
  // Describe this function...
  function open_link() {
    link("https://sdgs.un.org/goals/goal13");}
  
  function show_img_fire() {
    var x = document.getElementsByClassName("show-img")[0]
    
    x.style.display = 'block'
    x.style.backgroundImage = 'url("https://upload.wikimedia.org/wikipedia/commons/3/36/Large_bonfire.jpg")';
  }
  
  function show_img_water() {
     var x = document.getElementsByClassName("show-img")[0]
    
    x.style.display = 'block' 
    
    x.style.backgroundImage = 'url("https://www.researchgate.net/profile/Frederique-Drullion/publication/259312845/figure/fig3/AS:375912127320066@1466635843357/Wind-over-wave-groups-Typical-random-groups-of-ocean-waves-not-breaking-but-with-sharp_Q640.jpg")';  
  }
  
  function show_img_air() {
      var x = document.getElementsByClassName("show-img")[0]
    
    x.style.display = 'block'
    
    x.style.backgroundImage = 'url("https://energyeducation.ca/wiki/images/d/d3/Air-2716_640.jpg")';  
  }
  
  function show_img_snow() {
      var x = document.getElementsByClassName("show-img")[0]
    
    x.style.display = 'block'
  
    x.style.backgroundImage = 'url("https://www.birdsandblooms.com/wp-content/uploads/2021/01/COUwpc19_katherine_plessner_6_preview.jpg?fit=700,479")';  
  }
  `;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
