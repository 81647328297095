import { fn } from "../api";

const html = `<div id="output">
<div class="hero"> 
  <div class="hero-infographic">
    <div class="hero-number">
      <h3 class="hero-number-header"> 1 in 3</h3> 
    </div>
      <div class="test">
        <p class="caption">1 in 3 women under 35 years of age experience sexual harrassment at least once.</p>
      </div>
    </div>
  </div>
</div>
<div class="cards">
  <div class="card">
    <h4 class="card-title">Sustainable Development Goal 5</h4>
    <p class="card-text">Gender Equality</p>
    <p class="card-text">Achieve gender equality and empower all women and girls</p>
  </div>
  <div class="card">
    <h4 class="card-title">Targets</h4>
    <p class="card-text">5.1: By 2030, End all forms of discrimination against all women and girls everywhere</p>
    <p class="card-text">5.2: By 2030,Eliminate all forms of violence against all women and girls in the public and private spheres, including trafficking and sexual and other types of exploitation</p>
    <p class="card-text">5.3: By 2030, Eliminate all harmful practices, such as child, early and forced marriage and female genital mutilation</p>
    <p class="card-text">5.4 By 2030, Recognize and value unpaid care and domestic work through the provision of public services, infrastructure and social protection policies and the promotion of shared responsibility within the household and the family as nationally appropriate</p>
  </div>
  <div class="card">
    <h4 class="card-title">Impact</h4>
    <p class="card-text">Ends violence, harrassment, and overall mistreatment against women</p>
    <p class="card-text">Reduces rates of anxiety in women and reduces the need for women to use weapons for self defense. </p>
    <p class="card-text">Reduces the higher risk of sexual or gender based violence for young women and reduces the chance for children to become young mothers.</p>
    <p class="card-text">Reduces the poverty rates for women and improves the rates of which women are promoted. </p>
  </div>
</div>
<div class="description">
  <h1 class="description">Why you should care</h1>
  <p class="description"></p>
</div>
<div class="meat"
   <h6 class="meat"> Women and girls account for half of the world's population and, as a result, half of its potential. Inequalities that girls face can begin early and persist throughout their lives, leading to a higher mortality rate. One-third of developing countries nevertheless lack gender equality in school. Girls continue to face barriers to enrollment in both primary and secondary school. Because of the struggle to obtain a good education, there is a lack of access to skills and limited opportunities in the workforce, both of which are necessary for expanding economic growth and promoting social development. Young marriage has an impact on girls' education. Young marriage has a greater impact on girls' education than it does on boys, and it is associated with prolonged bleeding, infection, birth complications, infertility, and death.</h6>
<p class="meat"></p>
   </div>
<div class="subscribe">
  <div class="subscribe-title"> <button value="test" class="submit" onclick="open_link()">Learn More About Gender Equality</button><button value="test" class="submit" onclick="open_link()">SDG page</button></div>
</div>
</div>`;

const css = `
body {
      align-items: center;flex-direction: column;display: flex;font-family: Trebuchet MS, sans-serif;
  }
.submit {
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  }
.hero-infographic {
  border-radius: 300px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 300px;
  padding-bottom: 255px;
  
  }
.caption {
      color: #ffffff;
  text-align: center;
  font-size: 30px;
  width: 1000px;
  text-shadow: -1px -1px 0 #ef402c, 1px -1px 0 #ef402c, -1px 1px 0 #ef402c, 1px 1px 0 #000;
  
  }
.test {
  justify-content: center;
  display: block;
  align-items: center;
  margin-top: 30px;
  background-color: rgba(115,38,29,.4); 

  }
.card-title {
      margin-bottom: 20px;font-size: 20px;
  }
.subscribe-title {
      margin-bottom: 20px;
  font-size: 20px;
  
align-items: flex-start;
flex-direction: row;
}
.subscribe {
  justify-content: center;display: flex;
  margin-top: 40px;
  }
.card {
      margin-bottom: 40px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #ef402c;
  margin-right: 20px;
  margin-left: 0px;
  width: 1000px;
  box-shadow: 10px 10px 10px #c0c0c0;
      
  }
.cards {
      margin-top: 40px;
  width: 1060px;
  justify-content: center;
  display: flex;
  }
.hero-number-header {
  text-align: center;
  font-size: 70px;
  justify-content: center;
  margin-bottom: 0px;
 background-color: #ffffff;
  border-radius: 60px;
  padding: 20px;
width: 300px;
  color: #B22B1B;
  text-shadow: -1px -1px 0 #73261D, 1px -1px 0 #73261D, -1px 1px 0 #73261D, 1px 1px 0 #73261D;
  }
.hero {  
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: url("https://tinyurl.com/yxk25ahu");
  width: 1000px;
  height: 400px;
  box-shadow: 10px 10px 10px #c0c0c0;
  }
.description {
      text-align: center;
  font-family: Tahoma, sans-serif;
}
  .meat {
    font-size: 20px;
 
  }
`;

const js = `function link(e) {
  window.open(e,"_blank")}
  
  // Describe this function...
  function open_link() {
    link("https://www.un.org/sustainabledevelopment/gender-equality/");}
  `;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
