import React, { useLayoutEffect } from "react";
import PROJECTS from "./projects";
import SiteListItem from "./SiteListItem";
import Modal from "./Modal";
import Videos from "./Videos";

import broadcom from "./media/broadcom.png";
import bnpParibas from "./media/bnpParibas.png";
import musicAndYouthAlliance from "./media/musicAndYouthAlliance.png";

function postItem({ sdg, email, name }) {
  return fetch(
    "https://2687ii4nwd.execute-api.us-east-1.amazonaws.com/datastore_api"
  )
    .then((res) => res.json())
    .then((res) => {
      const items = res?.Items;
      const highestId = items.reduce((acc, item) => {
        if (item.Id > acc) {
          return item.Id;
        }
        return acc;
      }, 0);

      const Id = highestId + 1;

      return fetch(
        "https://2687ii4nwd.execute-api.us-east-1.amazonaws.com/datastore-post",
        {
          method: "POST",
          body: JSON.stringify({
            Id: Id.toString(),
            name,
            email,
            sdg,
          }),
        }
      );
    });
}

const Home = () => {
  const [recentSignups, setRecentSignups] = React.useState([]);
  const [pledge, setPledge] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const x_nav = () => {
    var x_menu = document.getElementById("sa-mobile_menu_holder");
    x_menu.classList.toggle("hidden");

    var x_btn_open = document.getElementById("sa-btn_open");
    var x_btn_close = document.getElementById("sa-btn_close");
    x_btn_open.classList.toggle("hidden");
    x_btn_close.classList.toggle("hidden");
  };

  useLayoutEffect(() => {
    // fetch recent signups
    fetch(
      "https://2687ii4nwd.execute-api.us-east-1.amazonaws.com/datastore_api"
    )
      .then((res) => res.json())
      .then((data) => {
        setRecentSignups(data?.Items);
      });

    const href = window.location.href;

    // push anchor tag to url
    window.history.pushState({}, "", href);

    if (!href) return;

    const anchorHash = href.split("#")[1];
    if (!anchorHash) return;

    const anchor = document.querySelector(`#${anchorHash}`);
    anchor.scrollIntoView();
  }, []);

  const scrollToView = (e) => {
    e.preventDefault();
    // get params
    const href = e.target?.href;

    // push anchor tag to url
    window.history.pushState({}, "", href);

    if (!href) return;

    const anchorHash = e.target?.href.split("#")[1];
    if (!anchorHash) return;

    const anchor = document.querySelector(`#${anchorHash}`);
    anchor.scrollIntoView();
  };

  const submitPledge = (pledge) => {
    setLoading(true);
    postItem(pledge)
      .then((res) => res.json())
      .then((data) => {
        setPledge(null);
        setLoading(false);
      });
  };

  const openPledgeModal = (pledge = "all") => {
    setPledge(pledge);
  };

  const onClose = () => {
    setPledge(null);
  };

  return (
    <div className="bg-white">
      {pledge && (
        <Modal
          submitPledge={submitPledge}
          onClose={onClose}
          pledge={pledge}
          loading={loading}
        />
      )}
      <header className="sticky inset-x-0 top-0 z-50 bg-white">
        <nav
          className="flex items-center justify-between p-6 lg:px-8 shadow-lg"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a
              href="https://renaissanceyouth.org/"
              target="_blank"
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Let's Work Together SDG</span>
              <img
                className="h-8 w-auto"
                src="https://renaissanceyouth.org/img/paypal-ryc-logo.png"
                alt=""
              />
            </a>
          </div>
          <div className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 lg:hidden cursor-pointer">
            Projects Pledged:
            <span className="inline-flex items-center rounded-md px-2.5 py-0.5 text-base font-medium bg-green-100 text-green-800 capitalize ml-2">
              {recentSignups.length}
            </span>
          </div>
          <div className="flex lg:hidden">
            <button
              onClick={x_nav}
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                id="x_btn_open"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a
              href="#"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              About
            </a>

            <a
              href="https://rycsdg.substack.com/archive"
              target="_blank"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Projects Blog
            </a>

            <a
              href="#why"
              className="text-base font-semibold leading-6 text-gray-900"
              onClick={scrollToView}
            >
              Why?
            </a>

            <a
              href="https://www.youtube.com/playlist?list=PLrArXc589hyul1ET6oROQbJEEkBIwEMl3"
              target="_blank"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Videos
            </a>

            <a
              href="https://www.paypal.com/donate/?cmd=_donations&business=paypal@renaissanceyouth.org&return=https://renaissanceyouth.org/support.php%3Fthankyou=donation"
              target="_blank"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Donate
            </a>

            <a
              href="#faq"
              className="text-base font-semibold leading-6 text-gray-900"
              onClick={scrollToView}
            >
              FAQ
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer"
            >
              Projects Pledged:
              <span className="inline-flex items-center rounded-md px-2.5 py-0.5 text-base font-medium bg-green-100 text-green-800 capitalize ml-2">
                {recentSignups.length}
              </span>
            </a>
          </div>
        </nav>
        <div
          id="sa-mobile_menu_holder"
          className="hidden lg:hidden"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 z-50"></div>
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://renaissanceyouth.org/img/paypal-ryc-logo.png"
                  alt=""
                />
              </a>
              <button
                onClick={x_nav}
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  id="sa-btn_close"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    About
                  </a>

                  <a
                    href="https://rycsdg.substack.com/archive"
                    target="_blank"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Projects Blog
                  </a>

                  <a
                    href="#why"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={scrollToView}
                  >
                    Why?
                  </a>

                  <a
                    href="https://www.youtube.com/playlist?list=PLrArXc589hyul1ET6oROQbJEEkBIwEMl3"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    target="_blank"
                  >
                    Videos
                  </a>

                  <a
                    href="https://www.paypal.com/donate/?cmd=_donations&business=paypal@renaissanceyouth.org&return=https://renaissanceyouth.org/support.php%3Fthankyou=donation"
                    target="_blank"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Donate
                  </a>

                  <a
                    href="#faq"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={scrollToView}
                  >
                    FAQ
                  </a>
                </div>
                <div className="py-6">
                  <a
                    target="_blank"
                    href="https://sdgs.un.org/goals"
                    className="flex items-center -mx-3 rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    <span>View SDG website</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 text-blue-600 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative isolate overflow-hidden bg-gray-900 pb-16">
          In
          <img
            src="https://renaissanceyouth.org/img/EMS-bg-133.jpg"
            alt=""
            className="absolute inset-0 -z-10 max-sm:h-[50%] max-sm:object-cover h-full w-full object-scale-down object-top"
            style={{ filter: "brightness(0.25)" }}
          />
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
            <svg
              className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                fillOpacity=".2"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset="1" stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl max-sm:pt-4 max-sm:pb-20 xs:pt-48 xs:pb-24 sm:pt-8 sm:pb-32 md:pt-24 md:pb-32 md:mb-12 lg:py-48 xl:pb-70 xl:mb-32 xl:pt-48">
              <div className="text-center">
                <h1
                  className="text-4xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl lg:text-6xl xl:7xl"
                  style={{
                    textShadow: "5px 5px black",
                    letterSpacing: "2px",
                  }}
                >
                  <span style={{ color: "rgb(241, 194, 50)" }}>RYC</span> Let's
                  Work Together{" "}
                  <span style={{ color: "rgb(0, 0, 255)" }}>S</span>
                  <span style={{ color: "rgb(255, 153, 0)" }}>D</span>
                  <span style={{ color: "rgb(255, 0, 0)" }}>G</span>
                </h1>
                <p
                  className="mt-6 text-lg leading-8 text-gray-300"
                  style={{ fontSize: "1rem" }}
                >
                  The Renaissance Youth Center's Music With A Message band will
                  launch the "Let's Work Together Tour" in summer 2023,
                  encouraging youth and youth programs to address the 17
                  Sustainable Development Goals in their communities through
                  concerts and community events throughout the world.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-white"
                    onClick={() => openPledgeModal()}
                  >
                    Pledge a project
                  </a>
                  <a
                    href="https://www.paypal.com/donate/?cmd=_donations&business=paypal@renaissanceyouth.org&return=https://renaissanceyouth.org/support.php%3Fthankyou=donation"
                    className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    target="_blank"
                  >
                    Donate
                  </a>
                </div>
              </div>
            </div>
            <Videos />
          </div>
          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                fillOpacity=".2"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset="1" stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="bg-white py-12 sm:py-24" id="why">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Renaissance Youth Center's Music With A Message Presents
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                SDG Tour for Sustainability
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                This Summer 2023, Renaissance Youth Center's Music With A
                Message (MWAM) band will kick off the “Let's Work Together
                Tour”, addressing the 17 Sustainable Development Goals.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-16 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                      ></path>
                    </svg>
                    About the tour
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">
                      This tour will feature talented young musicians,
                      scientists, coders, engineers, and other community-minded
                      youth from various communities. We will challenge youth
                      and youth programs across parts of the USA to partner up
                      to focus on one or more of the 17 Sustainable Development
                      Goals of the United Nations (SDGs). Whether tackling
                      hunger in their community by organizing a food drive or
                      improving education by creating a donated book library,
                      they will be thinking globally by acting locally.
                    </p>
                    <p className="mt-6">
                      <a
                        href="https://renaissanceyouth.org/ryc-sdgs"
                        target="_blank"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        Learn more about the tour{" "}
                        <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="white"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
                      ></path>
                    </svg>
                    About the SDGs
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">
                      Young people at Renaissance Youth Center believe that all
                      aspects of meaningful change are embodied in the 17
                      Sustainable Development Goals of the United Nations (SDGs)
                      that were established in 2015 to create a "shared
                      blueprint” for peace and prosperity for people and the
                      planet, now and into the future". The SDGs emphasize the
                      interconnected environmental, social, and economic aspects
                      of our shared life on Earth.
                    </p>
                    <p className="mt-6">
                      <a
                        href="https://sdgs.un.org/goals"
                        target="_blank"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        Learn more about the SDGs{" "}
                        <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z"
                      ></path>
                    </svg>
                    Music With a Message's Anthem
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">
                      To inspire people everywhere to adopt the SDGs, MWAM has
                      created an anthem that the students believe can transform
                      the world. This song debuted at the International Media
                      Summit of the United Nations in the fall of 2022.
                    </p>
                    <p className="mt-6">
                      <a
                        href="https://www.youtube.com/watch?v=ERL_oBKGYLU&list=PLrArXc589hyul1ET6oROQbJEEkBIwEMl3&index=1"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                        target="_blank"
                      >
                        Listen to Music With a Message (MWAM)'s{" "}
                        <em>"Let's Work Together"</em>{" "}
                        <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      ></path>
                    </svg>
                    Changing communities
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">
                      Take up the challenge to be the change you want for your
                      community and the Music With A Message band may pop up in
                      your town for a concert or stop by your neighborhood to
                      highlight your work. Tour stops will be featured on our
                      SDG website and you may be eligible for a small grant to
                      continue changing the world. Follow #RYCletsworktogether
                      on all social media platforms and feel free to join in on
                      the conversation.
                    </p>
                    <p className="mt-6">
                      <a
                        href="https://twitter.com/search?q=%23RYCletsworktogether&src=typed_query&f=live"
                        target="_blank"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        Follow #RYCletsworktogether{" "}
                        <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div
          className="bg-gray-900 isolate px-6 lg:px-8 -mt-14 pt-14"
          id="projects"
        >
          <div className="mx-auto max-w-7xl px-6 text-center lg:px-8 py-16">
            <div className="mx-auto max-w-2xl">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Student Projects
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-400">
                Renaissance Youth Center's Code the Bronx program presents the
                17 SDG's
              </p>
            </div>
            <ul
              role="list"
              className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8"
            >
              {Object.keys(PROJECTS).map((id) => (
                <SiteListItem
                  sdgGoal={PROJECTS[id].sdgGoal}
                  studentName={PROJECTS[id].studentName}
                  path={id}
                  imageSrc={PROJECTS[id].imageSrc}
                  openPledgeModal={openPledgeModal}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="relative isolate">
          <div className="relative">
            <div className="absolute inset-x-0 -top-1/2 -z-10 pointer-events-none -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
              <svg
                viewBox="0 0 1313 771"
                aria-hidden="true"
                className="ml-[max(50%,38rem)] w-[82.0625rem]"
              >
                <path
                  id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
                  fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
                  d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
                />
                <defs>
                  <linearGradient
                    id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
                    x1="1313.17"
                    x2="-88.881"
                    y1=".201"
                    y2="539.417"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset="1" stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8" id="sdgs">
          <div className="bg-white">
            <div className="mx-auto max-w-7xl pt-16 px-6 sm:pt-24 sm:pb-4 lg:px-8">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                United Nation's Sustainable Development Goals
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ margin: "20px auto auto auto" }}>
                  Learn more about the goals and their history at{" "}
                  <a
                    href="https://sdgs.un.org/goals"
                    className="text-sm font-semibold leading-6 text-indigo-600"
                    target="_blank"
                  >
                    sdg.un.org/goals
                  </a>{" "}
                  <img
                    className="transform transition-transform duration-300 hover:scale-125"
                    style={{ margin: "40px auto auto auto", cursor: "pointer" }}
                    height="250"
                    width="250"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/3/31/Sustainable_Development_Goals_logo.svg/1200px-Sustainable_Development_Goals_logo.svg.png"
                    onClick={() =>
                      window.open("https://sdgs.un.org/goals", "_blank")
                    }
                  />{" "}
                </div>
                <div style={{ margin: "20px auto auto auto" }}>
                  Learn more about the{" "}
                  <a
                    href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQjwx5qoBhDyARIsAPbMagA1a1lI15dtOx6YztCrU79OOHpqg--lkVtYTBMZKaHnwhm9_bd8RLUaAgpVEALw_wcB"
                    className="text-sm font-semibold leading-6 text-indigo-600"
                    target="_blank"
                  >
                    SDGs in action
                  </a>{" "}
                  <img
                    style={{ margin: "40px auto auto auto", cursor: "pointer" }}
                    className="transform transition-transform duration-300 hover:scale-125"
                    height="400"
                    width="400"
                    src="https://i0.wp.com/ccpa.eu/wp-content/uploads/2022/04/sdg_-_wheel_-_smaller_2-1.png"
                    onClick={() => {
                      window.open(
                        "https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQjwx5qoBhDyARIsAPbMagA1a1lI15dtOx6YztCrU79OOHpqg--lkVtYTBMZKaHnwhm9_bd8RLUaAgpVEALw_wcB",
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8" id="faq">
          <div className="bg-white">
            <div className="mx-auto max-w-7xl pt-16 px-6 sm:pt-24 pb-12 sm:pb-20 lg:px-8">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Frequently asked questions
              </h2>
              <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600">
                Here are some frequently asked questions and more info on what
                you can do to get involved.
              </p>
              <div className="mt-20">
                <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      How do I submit an SDG project?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Projects can be submitted to{" "}
                      <a
                        href="mailto:rycsdg@renaissanceyouth.org"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                        target="_blank"
                      >
                        rycsdg@renaissanceyouth.org
                      </a>
                      . You can submit a video of your project, a photo gallery
                      of the project, or even a link to an article highlighting
                      your work. Don't forget to complete{" "}
                      <a
                        href="#four-steps"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        all four steps.
                      </a>{" "}
                      No project is too small to get involved.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Can a group submit a project or must it be an individual
                      project?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Group projects can be submitted to{" "}
                      <a
                        href="mailto:rycsdg@renaissanceyouth.org"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                        target="_blank"
                      >
                        rycsdg@renaissanceyouth.org
                      </a>
                      . Assign someone in the group to be the designated
                      contact.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      How do I find out if my project has been selected for the
                      SDG tour?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      We will contact you through email from{" "}
                      <a
                        href="mailto:rycsdg@renaissanceyouth.org"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                        target="_blank"
                      >
                        rycsdg@renaissanceyouth.org
                      </a>{" "}
                      with details and the next steps.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Where can I find out about SDG tour dates?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Tour dates can be found here on the{" "}
                      <a
                        href="https://www.renaissanceyouth.org/ryc-sdgs"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                        target="_blank"
                      >
                        website
                      </a>
                      . Join us at the tour stops near you!
                    </dd>
                  </div>

                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      What ages qualify for the youth grants?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Awardees who submit an SDG project must be ages 8-24 to be
                      eligible for the youth grants.
                    </dd>
                  </div>

                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Are there other ways to get involved besides submitting a
                      project?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Yes! Follow #RYCletsworktogether on all social media
                      platforms and feel free to join in on the conversation. If
                      you would like to make a donation,{" "}
                      <a
                        href="https://www.paypal.com/donate/?cmd=_donations&business=paypal@renaissanceyouth.org&return=https://renaissanceyouth.org/support.php%3Fthankyou=donation"
                        target="_blank"
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        click here.
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white pb-12 sm:pb-24" id="four-steps">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              RYC's approach to the 17 SDGs
            </h2>
            <div className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-2 gap-x-16 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    Identify the problem that you or your group would like to
                    solve.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="white"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                      ></path>
                    </svg>
                    Offer a thoughtful solution to solve the problem.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      ></path>
                    </svg>
                    Create a model or draw up a plan alone as a group or with
                    outside partners.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt
                    className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
                    style={{ flexWrap: "wrap" }}
                  >
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      ></path>
                    </svg>
                    Put the plan in motion, and don't forget to celebrate.
                    <i style={{ fontSize: "14px", marginLeft: "2rem" }}>
                      *Find success in doing not just what you achieve!
                    </i>
                  </dt>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="bg-white pb-12 sm:pb-24" id="four-steps">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Really Quick RYC SDG Projects
            </h2>
            <div className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-2 gap-x-16 gap-y-16 lg:max-w-none lg:grid-cols-2">
                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-5 w-5 flex-none text-indigo-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                      ></path>
                    </svg>
                    Recycle in your neighborhood for a day.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="white"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                      ></path>
                    </svg>
                    Plant a Tree.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      ></path>
                    </svg>
                    Write a petition and get signatures.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt
                    className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
                    style={{ flexWrap: "wrap" }}
                  >
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      ></path>
                    </svg>
                    Create/distribute informative posters/pamphlets on health
                    topics
                  </dt>
                </div>
                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-5 w-5 flex-none text-indigo-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                      ></path>
                    </svg>
                    Host a food or can drive.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-5 w-5 flex-none text-indigo-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                      ></path>
                    </svg>
                    Walk or ride a bike for a day.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      ></path>
                    </svg>
                    Start a book drive.
                  </dt>
                </div>

                <div className="flex flex-col">
                  <dt
                    className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
                    style={{ flexWrap: "wrap" }}
                  >
                    <svg
                      className="h-5 w-5 flex-none text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                      ></path>
                    </svg>
                    Track caloric intake for a day.
                  </dt>
                </div>
                <div className="flex flex-col">
                  <dt
                    className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
                    style={{ flexWrap: "wrap" }}
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-5 w-5 flex-none text-indigo-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      ></path>
                    </svg>
                    Tutor or mentor a student.
                  </dt>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="bg-white pb-12 sm:pb-12" id="sponsors">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              A special thanks to our sponsors
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              margin: "25px auto auto auto",
              width: "80vw",
              flexWrap: "wrap",
            }}
          >
            <img
              style={{
                width: "auto",
                height: 75,
                margin: "0 20px",
                cursor: "pointer",
              }}
              src={broadcom}
              onClick={() => window.open("https://broadcomfoundation.org/")}
              alt="Broadcom"
            />
            <img
              style={{
                width: "auto",
                height: 150,
                margin: "0 20px",
                cursor: "pointer",
              }}
              src={bnpParibas}
              onClick={() =>
                window.open("https://group.bnpparibas/en/", "_blank")
              }
              alt="BNP Paribas"
            />
            <img
              style={{
                width: "auto",
                height: 150,
                margin: "0 20px",
                cursor: "pointer",
              }}
              src={musicAndYouthAlliance}
              onClick={() =>
                window.open(
                  "https://www.musicandyouthdevelopment.org/mydas-timeline",
                  "_blank"
                )
              }
              alt="Music and Youth Alliance"
            />
          </div>
        </div>
      </main>

      <footer className="mt-6 sm:mt-12 bg-gray-900">
        <div className="mx-auto max-w-7xl overflow-hidden py-20 px-6 sm:py-24 lg:px-8">
          <nav
            className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
            aria-label="Footer"
          >
            <div className="pb-6">
              <a href="#why" className="text-sm leading-6 text-white">
                About
              </a>
            </div>

            <div className="pb-6">
              <a
                href="https://rycsdg.substack.com/archive"
                target="_blank"
                className="text-sm leading-6 text-white"
              >
                Projects Blog
              </a>
            </div>

            <div className="pb-6">
              <a
                href="https://www.youtube.com/playlist?list=PLrArXc589hyul1ET6oROQbJEEkBIwEMl3"
                className="text-sm leading-6 text-white"
                target="_blank"
              >
                Videos
              </a>
            </div>
          </nav>
          <div className="mt-10 flex justify-center space-x-10">
            <a
              href="https://www.facebook.com/RenaissanceYouthCenter/"
              target="_blank"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/renaissanceyouthcenter/?hl=en"
              target="_blank"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.youtube.com/playlist?list=PLrArXc589hyul1ET6oROQbJEEkBIwEMl3"
              target="_blank"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">YouTube</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
          <p className="text-sm text-center mt-12 leading-6 text-white hover:text-white-900">
            For more info contact{" "}
            <a href="mailto:rycsdg@renaissanceyouth.org">
              rycsdg@renaissanceyouth.org
            </a>
          </p>
          <p className="mt-10 text-center text-xs leading-5 text-gray-500">
            &copy; © Copyright 2023 Renaissance Youth Center. A non-profit
            501(c)(3) organization.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
