import { fn } from "../api";

const html = `<div id="output">
    <div class="Top-slab">
      <div class="languages">
        <div class="English">
          <button value="test" class="ENBTN" onclick="test()">
            English
          </button>
        </div>
        <div class="Spanish">
          <button value="test" class="ESBTN" onclick="test()">
            Espanol
          </button>
        </div>
      </div>
    </div>
    <div class="Header">
      <p class="big-number">1,300,000,000</p>
      <h1 class="title">metric tons of solid waste per year</h1>
    </div>

    <div class="Bx1">
      <div class="ijoij">
        <h1 class="fwfewfew">Solid waste</h1>
      </div>
      <div class="jdcd">
        <p class="iuytre">
        The average american produces 5.91 pounds of trash a day yet only around 1.51 pounds of it are recycled. over time the unrecycled trash can build up, causinng air pollution, global warming, as well as polluting our soil and water. The way to prevent this is very simple, all it takes is more people recycling their trash to help keep our communites as well as our environment clean.
        </p>
      </div>
    </div>

    <div class="Bx1">
      <div class="ijoij">
        <h1 class="fwfewfew">Transportation</h1>
      </div>
      <div class="jdcd">
        <p class="iuytre">
        Transportation is responsible for a significant amount of global carbon emissions, with cars, trucks, and planes being major contributors. To reduce your carbon footprint, consider using public transportation, carpooling, or cycling instead of driving alone. Choosing an electric vehicle or hybrid car, when possible, can also significantly reduce your carbon emissions.
        </p>
      </div>
    </div>

    <div class="Bx1">
      <div class="ijoij">
        <h1 class="fwfewfew">Affordable homes</h1>
      </div>
      <div class="jdcd">
        <p class="iuytre">
        The affordable housing crisis affects millions of people worldwide, with nearly 1.6 billion lacking adequate housing. Governments can take action by providing funding for affordable housing initiatives and implementing policies that encourage the development of affordable housing. Additionally, increasing access to education and job opportunities can help individuals and families afford safe and stable housing.
        </p>
      </div>
    </div>
    <div class="bottom-slab">
      <div id="output">
        <input type="text" placeholder="enter your name" class="CTA" id="nameInput" />

        <button id="MI" class="MI" onclick="postItem('Clean Water & Sanitation');openlink();">
          Learn more!
        </button>
      </div>
    </div>
  </div>
`;

const css = `.Top-slab {
    color: #5f5e5e;
    background-color: #5f5e5e;
  }
  .languages {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
  .Header {
    padding: 20px;
    background-image: url(https://www.lilanduseandzoning.com/wp-content/uploads/sites/128/2021/08/shutterstock_712781533.jpg);
    text-align: center;
    height: 500px;
    background-size:cover;
    background-repeat:none;
    padding-top:300px;
    padding-bottom:300px;
    
  }
  .Bx1 {
    background-color: #ffcc33;
    border-radius: 20px;
    justify-content: center;
    display: flex;
    margin:10px;
  }
  .iuytre {
    padding: 25px;
    margin-left: 25px;
  }
  .ijoij {
    padding: 10px;
    margin: 15px;
  }
  .big-number{
    font-size:10rem;
    margin:0;
  }
  .MI{
    width:220px;
    padding:5px;
    
    
  }
  .CTA{
    
    width:220px;
    padding:5px;
    margin-left:10px;
  
  }
  
  .bottom-slab{
    background-color:#5f5e5e;
    padding:10px
  }
  `;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
        <script type="text/javascript">
            function openlink(){
                alert("you will be brought to another site")
                window.open("https://sdgs.un.org/","_blank")
            }
            ${fn}
        </script>
    </head>
    <body>
        ${html}
    </body>
</html>
`;
