import { fn } from "../api";

const html = `<div id="output">
<div class="hero">
  <p class="big-number">85%</p>
  <h1 class="sub-text">percent of humans are affected by climate change</h1>
</div>
</div>

<div class="facts-section">
            
<div class="card">
 

  <p>     Fact about climate change: Climate change is the most severe problem that we are facing today, more serious even than the threat of terrorism. A nation that destroys its soils destroys itself. Forests are the lungs of our land, purifying the air and giving fresh strength to our people. Another fact is some are more dramatic than others: 800 to 600 million years ago, the Earth was so cold that it was covered with ice. Every 40,000 years, the Earth changes positions and this cause climate change. The Earth is still in an Ice Age which started 2.5 million years ago. Is climate change getting better or worse? If greenhouse gas emissions are increasing — which they are, according to NPR — then technically, climate change is getting worse. But before you lose hope and fall victim to climate doom, it's important to remember that our situation is still very complex.</p></div>
<div class="card">
  <p>Coal is a fossil fuel, and is the dirtiest of them all, responsible for over 0.3C of the 1C increase in global average temperatures. This makes it the single largest source of global temperature rise. Oil releases a huge amount of carbon when burned - approximately a third of the world's total carbon emissions.Climate change refers to long-term shifts in temperatures and weather patterns. These shifts may be natural, but since the 1800s, human activities have been the main driver of climate change, primarily due to the burning of fossil fuels (like coal, oil, and gas) which produces heat-trapping gases.The potential future effects of global climate change include more frequent wildfires, longer periods of drought in some regions, and an increase in the duration and intensity of tropical storms.
</p>
</div>
<div class="card">
     <p>Hotter temperatures. As greenhouse gas concentrations rise, so does the global surface temperature. 
More severe storms,
Increased drought,
A warming, rising ocean,
Loss of species,
Not enough food,
More health risks,
Poverty and displacement.Climate change is already impacting health in a myriad of ways, including by leading to death and illness from increasingly frequent extreme weather events, such as heatwaves, storms and floods, the disruption of food systems, increases in zoonoses and food-, water- and vector-borne diseases, and mental health issues.For example, many places have experienced changes in rainfall, resulting in more floods, droughts, or intense rain, as well as more frequent and severe heat waves. The planet's oceans and glaciers have also experienced changes and the oceans are warming.</p>


</div>
</div>
</div>
<div class="button">
<div id="output">
    <input type="text" placeholder="Email" class="class" id="nameInput"/>
</div>
<button onclick="postItem('Climate Action');link()">Pledge an hour</button>
</div>`;

const css = `.hero {
    text-align: center;
    background: url(https://scx2.b-cdn.net/gfx/news/hires/2021/hurricane.jpg);
    padding: 150px 0;
  }
  .big-number{
    font-size: 10rem;
    margin: 0;
  }
  .sub-text {
    font-size: 2rem;
  }
  .img-container img {
    margin: 0 auto;
    display: block;
  }
  .card {
    width: 300px;
    min-height: 300px;
    border: solid 2px black;
    margin: 20px;
      background-color: cyan
  }
  .facts-section{
    margin: 0 ;
    display: flex;
    justify-content: center;
    
  }
  .Fact{ 
    justify-content: center;
  }
  .button{
    justify-content: center;
    display:flex;
    Margin-right: 4px
      justify-content: space-between;
  }
`;

const js = `function link(e) {
    window.open('https://sdgs.un.org/goals/goal13',"_blank")}
    function class_3() {
      link("https://sdgs.un.org/goals/goal13");}
    function class_5() {
      link("https://sdgs.un.org/goals/goal13");}
    function alert2(e) {
    alert(e)}
    function class_7() {
      alert2("DONT LET IT REACH 100%");}`;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
