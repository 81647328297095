import { project as matthewSrc } from "./matthew";
import { project as michelleSrc } from "./michelle";
import { project as stephanieSrc } from "./stephanie";
import { project as melanieSrc } from "./melanie";
import { project as salifSrc } from "./salif";
import { project as robertSrc } from "./robert";
import { project as stephenSrc } from "./stephen";
import { project as adrielSrc } from "./adriel";
import { project as llianSrc } from "./llian";
import { project as josiahSrc } from "./josiah";
import { project as eliseMarieSrc } from "./elisemarie";
import { project as dannySrc } from "./danny";

import matthew from "../media/matthew.png";
import michelle from "../media/michelle.png";
import stephanie from "../media/stephanie.png";
import melanie from "../media/melanie.png";
import salif from "../media/salif.png";
import robert from "../media/robert.png";
import stephen from "../media/stephen.png";
import adriel from "../media/adriel.png";
import llian from "../media/llian.png";
import josiah from "../media/josiah.png";
import eliseMarie from "../media/elisemarie.png";
import danny from "../media/danny.png";

const PROJECTS = {
  sanitation: {
    src: matthewSrc,
    studentName: "Matthew",
    sdgGoal: "Clean Water and Sanitation",
    imageSrc: matthew,
  },
  "quality-education": {
    src: michelleSrc,
    studentName: "Michelle",
    sdgGoal: "Quality Education",
    imageSrc: michelle,
  },
  "preventing-deforestation": {
    src: stephanieSrc,
    studentName: "Stephanie",
    sdgGoal: "Preventing Deforestation",
    imageSrc: stephanie,
  },
  "accessible-education": {
    src: melanieSrc,
    studentName: "Melanie",
    sdgGoal: "Accessible Education",
    imageSrc: melanie,
  },
  "affordable-healthcare": {
    src: salifSrc,
    studentName: "Salif",
    sdgGoal: "Affordable Healthcare",
    imageSrc: salif,
  },
  "clean-energy": {
    src: robertSrc,
    studentName: "Robert",
    sdgGoal: "Clean Energy",
    imageSrc: robert,
  },
  "climate-action": {
    src: stephenSrc,
    studentName: "Stephen",
    sdgGoal: "Climate Action",
    imageSrc: stephen,
  },
  "good-health-well-being": {
    src: adrielSrc,
    studentName: "Adriel",
    sdgGoal: "Good Health and Well-Being",
    imageSrc: adriel,
  },
  "climate-change": {
    src: llianSrc,
    studentName: "llian",
    sdgGoal: "Climate Change",
    imageSrc: llian,
  },
  "life-below-water": {
    src: josiahSrc,
    studentName: "Josiah",
    sdgGoal: "Life Below Water",
    imageSrc: josiah,
  },
  "end-sexual-harassment": {
    src: eliseMarieSrc,
    studentName: "Elise Marie",
    sdgGoal: "End Sexual Harassment",
    imageSrc: eliseMarie,
  },
  "species-conservation": {
    src: dannySrc,
    studentName: "Danny",
    sdgGoal: "Species Conservation",
    imageSrc: danny,
  },
};

export default PROJECTS;
