import React from "react";

const SiteListItem = ({
  studentName,
  sdgGoal,
  path,
  imageSrc,
  openPledgeModal,
}) => {
  return (
    <li className="rounded-2xl bg-gray-800 py-10 px-8">
      <img
        className="object-cover mx-auto h-48 w-48 rounded-full md:h-56 md:w-56 bg-white"
        src={imageSrc}
        alt=""
      />
      <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">
        {studentName}
      </h3>
      <p className="text-sm leading-6 text-gray-400">{sdgGoal}</p>
      <ul className="mt-6 flex justify-center gap-x-6">
        <li>
          <a href={path} className="text-gray-400 hover:text-gray-300">
            <span className="text-blue-300">View Site</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            className="text-gray-300"
            onClick={() => openPledgeModal(sdgGoal)}
          >
            <span className="">Pledge</span>
          </a>
        </li>
      </ul>
    </li>
  );
};

export default SiteListItem;
