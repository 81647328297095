import { fn } from "../api";

const html = `
  <div>
    <div id="output">
      <div class="hero">
        <div class="title-container">
          <h1 class="Hero-title">264,000,000</h1>
          <h5 class="Hero-subtitle">
            children & adolescents don't have access to education
          </h5>
        </div>
      </div>
      <div class="cards">
        <div class="card">
          <h2 class="card-title">SDG #4: Quality Education</h2>
          <p class="card-text">What is the objective?</p>
          <p class="card-text">
            To ensure inclusive & equitable quality education & promote lifelong
            learning opportunities for all.
          </p>
        </div>
        <div class="card">
          <h2 class="card-title">Why is it important?</h2>
          <p class="card-text">
            Education is the foundation of human development.
          </p>
          <p class="card-text">
            Education helps with the development of our basic human life-skills
            & provides us with knowledge to better prepare us to become well,
            functioning, & successful members of society.
          </p>
        </div>
        <div class="card">
          <h2 class="card-title">How can we improve quality education?</h2>
          <p class="card-text">
            A bigger solution to help with education is tax reform. Ensure that
            funding schools around your community is a top priority. As well as,
            voicing your opinion to local & community policy makers to implant a
            change.{" "}
          </p>
        </div>
        <div class="card">
          <h2 class="card-title">When can I start?</h2>
          <p class="card-text">The answer is now!</p>
          <p class="card-text">
            It's never too late to make an impact in the world. Start by
            researching more over the worldwide educational crisis, knowledge is
            power.
          </p>
        </div>
        <div class="card">
          <h2 class="card-title">Where?</h2>
          <p class="card-text">
            Education is everywhere, but not always accessible. To tackle this
            global issue, start in your community.
          </p>
        </div>
      </div>
    </div>

    <div class="slideshow-container">
      <div class="myslide">
        <img
          src="https://t3.ftcdn.net/jpg/02/02/42/14/360_F_202421406_QPKY0374aJMV86MF21RCWFL84Ntm5zJL.jpg"
          class="img"
          style="width:50%"
        />
        <div class="text">
          Fact 1#: In the U.S. alone, every year, 1.2 million students drop out.
          That's 7,000 drop outs a day or 1 every 26 seconds.
        </div>
      </div>
    </div>

    <div class="pledgeinfo">Here's how you can help:</div>

    <div class="pledgediv">
      <input class="nameinput" placeholder="Enter your name" id="nameInput"></input>
      <button class onclick="postItem('education');link2();" />
      Pledge an hour!
      <button onclick="link()" class="extrainfo">
        Learn more!
      </button>
      <button onclick="link3()" class="SDG">
        SDG Website
      </button>
    </div>
  </div>
)`;

const css = `
.hero {
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    background-color: #ffffff;
    background-image: url(http://www.bcd-urbex.com/wp-content/uploads/2014/09/pripyat-schools-nurseries-26.jpg);
    background-size: cover;
    align-items: center;
    justify-content: center;
    height: 500px;
    padding: 200px;
  }
  .card-title {
    margin-bottom: 20px;
    font-size: 20px;
    color: #ffffff;
  }
  .cards {
    margin: 40px auto 0;
    width: 1320px;
    justify-content: space-between;
    display: flex;
  }
  .card {
    margin-bottom: 40px;
    text-align: center;
    padding: 22px;
    border-radius: 11px;
    background-color: #c42135;
    margin-right: 11px;
    margin-left: 11px;
    width: 300px;
    box-shadow: 11px 11px 11px #999999;
  }
  .card-text {
    color: #ffffff;
  }
  body {
    background-color: #ffffff;
  }
  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 900px;
  }
  
  /* .Hero-title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
  
  .Hero-title {
    margin-bottom: -30px;
  }
  
  /* .Hero-subtitle{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
  
  .title-container {
    height: 150px;
    background-color: rgba(5, 5, 5, 0.8);
    margin: 0px 250px;
    border-radius: 11px;
  }
  
  .myslide {
    position: relative;
    justify-content: center;
    align-conent: center;
    display: flex;
    margin: auto;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 150px;
    width: 25%;
    height: fit-content;
    text-align: center;
    background-color: rgba(5, 5, 5, 0.8);
    word-wrap: break-word;
  }
  
  .img {
    box-shadow: 11px 11px 11px #999999;
    border-radius: 5px;
  }
  
  .pledgeinfo {
    text-align: center;
    padding: 30px;
    font-size: 50px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #c42135;
    color: white;
  }
  
  .pledgediv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .pledgediv > button {
    border-radius: 5px;
    margin-left: 5px;
    background-color: gray;
  }
  button:hover {
    background-color: lightgray;
  }
`;

const js = `
  var img = document.querySelector(".img");

  var text = document.querySelector(".text");
  var pictures = [
    "https://t3.ftcdn.net/jpg/02/02/42/14/360_F_202421406_QPKY0374aJMV86MF21RCWFL84Ntm5zJL.jpg",
    "https://marvel-b1-cdn.bc0a.com/f00000000026007/resilienteducator.com/wp-content/uploads/2018/09/illiteracy-impact.jpg",
    "https://c.files.bbci.co.uk/85A9/production/_87771243_unesco2.jpg",
    "http://img.dunyanews.tv/news/2017/March/03-10-17/news_big_images/378398_71964706.jpg",
    "https://cdn.vanderbilt.edu/vu-news/files/20190417232243/841_20140122153826-gradstudentsilhouette.jpeg",
    "https://external-preview.redd.it/PRSwfmJ56t61wT53ES0U9R6uJYOI_B-el0Ao1a3vyWw.jpg?auto=webp&s=ea3b4dd318916d4f58359f9c8cf3da0cc655c9f9",
    "https://bloximages.newyork1.vip.townnews.com/thecentersquare.com/content/tncms/assets/v3/editorial/b/ac/bac14d52-6b6a-11e9-bad1-f7509679346e/5cc88210df32b.image.jpg?crop=1000%2C525%2C0%2C73&resize=1000%2C525&order=crop%2Cresize",
    "https://cloudfront-eu-central-1.images.arcpublishing.com/irishtimes/KJY3FHECTHIGQHQSJ4TJ7N7SBY.jpg",
    "https://imgix.bustle.com/uploads/image/2018/4/11/3950836d-d529-474a-9f41-1db99287c0c5-fotolia_188499809_subscription_monthly_m.jpg?w=1200&h=630&fit=crop&crop=faces&fm=jpg",
    "https://chalkbeat.brightspotcdn.com/dims4/default/399f2a5/2147483647/strip/false/crop/3000x2000+0+0/resize/1486x991!/quality/90/?url=https%3A%2F%2Fcdn.vox-cdn.com%2Fthumbor%2FIC3Q6HvUlmkj2Jira-16hHKEN2A%3D%2F0x0%3A3000x2000%2F3000x2000%2Ffilters%3Afocal%281500x1000%3A1501x1001%29%2Fcdn.vox-cdn.com%2Fuploads%2Fchorus_asset%2Ffile%2F19668820%2FDetroit_Southeastern_High_School_02.jpg",
  ];
  var texts = [
    "Fact 1#: In the U.S. alone, every year, 1.2 million students drop out. That's 7,000 drop outs a day or 1 every 26 seconds.",
    "Fact #2: More than a fifth of India's population lives under the official poverty line.",
    "Fact #3: In Kenya, men ages 15-29 who left school after 6 years, 6% are illiterate.",
    "Fact #4: Many Pakistani families cannot afford to send their children to school.",
    "Fact #5: Only 6.7% of the world has a degree.",
    "Fact #6: Most public schools in America are collectively underfunded by about $150 million every year.",
    "Fact #7: 175 million children throughout the world, under 5, aren't enrolled in pre-primary education.",
    "Fact #8: 825 million youth will not have necessary future workforce skills if quality education is not provided by 2030.",
    "Fact #9: Approximately 1 in 4 children in America grow up without learning how to read.",
    "Fact #10: Approximately 1/4 of schools of black and Latino students in America didn't offer Algebra II, & 1/3 didn't offer chemistry.",
  ];
  index = 0;
  setInterval(() => {
    img.src = pictures[index];
    text.innerText = texts[index];

    if (texts.length - 1 === index) {
      index = 0;
    } else {
      index += 1;
    }
  }, 4000);

  function link() {
    window.open("https://www.unicef.org/learning-crisis", "-blank");
  }

  function link2() {
    window.open("https://theirworld.org/", "-blank");
  }

  function link3() {
    window.open("https://sdgs.un.org/goals/goal4", "-blank");
  }
`;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
