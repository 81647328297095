import { fn } from "../api.js";

const html = `<div>
    <div class="topnav">
      <div class="nav-left">
        <div class="logo">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Sustainable_Development_Goal_4.png/1024px-Sustainable_Development_Goal_4.png"
            class="class"
            width="65"
            height="65"
          />
        </div>
      </div>

      <div class="nav-right">
        <a class="home-link active" href="#home">
          Home
        </a>
        <a href="#News">Current News</a>
        <a href="#Info">Information</a>
      </div>
    </div>
    <div class="hero">
      <img
        src="https://static01.nyt.com/images/2011/03/07/us/Classroom1_span/Classroom-articleLarge.jpg"
        class="class"
        width="950"
        height="615"
      />
      <img
        src="https://www.st-cecelia.org/wp-content/uploads/2019/05/Students-listening-to-their-teacher-in-the-classroom-min-1024x683.jpg"
        class="class"
        width="900"
        height="615"
      />
    </div>
    <div class="hero-infographic">
      <h1 class="class">Students in: Public School vs Private School</h1>
      <h1 class="class caption"> (91.06%) vs (8.94%)</h1>
      <div class="cards center">
        <div class="card wide click" onclick="text()">
          <h3 class="card-title1">Why is this important?</h3>
          <h3 class="flip1">~Flip~</h3>
          <p class="card-text1"></p>
        </div>
      </div>
      <div class="cards" width="1500" height="2000">
        <div class="card">
          <h4 class="card-title2">STEM</h4>
          <p class="card-text2">
            <a href="https://brandeis.precollegeprograms.org/climate-change?utm_term=high%20school%20stem%20programs&utm_campaign=brandeis_nb_climate_change&utm_source=adwords&utm_medium=ppc&hsa_acc=5131286297&hsa_cam=12979317559&hsa_grp=124612867169&hsa_ad=519700975088&hsa_src=g&hsa_tgt=kwd-851452162762&hsa_kw=high%20school%20stem%20programs&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gclid=EAIaIQobChMIhdiHyNKf_QIV8PLjBx3yCwFCEAAYASAAEgJBfvD_BwE">
              Become a Climate Scientist and Activist!
            </a>
          </p>
          <p class="card-text2">Information</p>
        </div>
        <div class="card">
          <h4 class="card-title3">Sports</h4>
          <p class="card-text3">
            7.1: By 2030, ensure universal access to affordable, reliable and
            modern energy services.
          </p>
          <p class="card-text3">
            7.2: By 2030, increase substantially the share of renewable energy
            in the global energy mix.
          </p>
          <p class="card-text">
            7.3: By 2030, double the global rate of improvement in energy
            efficiency.
          </p>
        </div>
        <div class="card">
          <h4 class="card-title4">Music</h4>
          <p class="card-text4">
            Improves access to energy and reduces poverty
          </p>
          <p class="card-text4">
            Reduces greenhouse gas emissions and combats climate change
          </p>
          <p class="card-text4">Supports economic growth and job creation</p>
          <p class="card-text4">Improves health and education outcomes</p>
        </div>
      </div>
      <div class="subscribe">
        <div class="subscribe-title">
          <input type="text" placeholder="Enter your name" class="email" id="nameInput" />
          <button value="test" class="submit" onclick="test()">
            Learn More
          </button>
          <button value="test" class="submit" onclick="postItem('Accessible Education for All');test();">
            Pledge an hour
          </button>
        </div>
      </div>
    </div>
  </div>`;

const css = `
body {
    margin:0;
    padding:0;
  }
  
  .topnav {
    background-color: #8a8a8a;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }
  .nav-right{
    height:65px
  }
  .nav-left {
    height: 65px;
  }
  .topnav a {
    float: right;
    color: #f2f2f2;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    font-size: 20px;
    height: 100%;
  }
  
  .topnav a:hover {
    background-color: #800000;
    color: white;
  }
  
  .home-link {
    height: 100%;
  }
  
  .topnav a.active {
    background-color: #800000;
    color: white;
  }
  }
  
  .hero-infographic {
    color: #000000;
    text-align: center;
    width: 800px;
    height: 150px;
    align-items: center;
    justify-content: center;
    display: block;
    font-family: Georgia, serif;
  }
  .hero {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  h1 {
    position: absolute;
    top:20%;
    left:22.5%;
    color: white;
    text-shadow: 3px 3px black;
    font-size: 50px;
  }
  
  .caption {
    top: 35%;
    left: 36.2%;
  }
  .email {
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    }
  .cards {
    margin: 40px auto;
    width: 1500px;
    justify-content: space-between;
    display: flex;
    }
  body {
    align-items: center;
    flex-direction: column;
    display: flex;
    font-family: Trebuchet MS, sans-serif;
    }
  .subscribe {
    justify-content: center;
    display: flex;
    margin-top: 40px;
    }
  .card {
    margin-bottom: 40px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #a2bd9e;
    margin-right: 10px;
    margin-left: 10px;
    width: 400px;
    height: 800px;
    box-shadow: 10px 10px 10px #c0c0c0;
    }
  .card-title {
    margin-bottom: 20px;
    font-size: 20px;
    }
  .submit {
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    }
  .subscribe-title {
    margin-bottom: 20px;
    font-size: 20px;
    }
  .center {
    display: flex;
    justify-content: center;
  }
  .wide {
    width: 1000px;
    height: 150px;
  }
  .card-text {
    display: none;
  }
  .card-title1 {
    font-size: 50px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .flip1 {
    margin-top: 0px;
    color: #3b3b3b;
  }
  .card-text1 {
    font: Times New Roman;
  }
  .card-title2 {
    font-size: 40px;
    margin-top: 0px;
  }
  .card-title3 {
    font-size: 40px;
    margin-top: 0px;
  }
  .card-title4 {
    font-size: 40px;
    margin-top: 0px;
  }
  `;

const js = `let introParagraph =
"Public schools are funded by the government while private schools otherwise depend on tuition. While a public education is easily accessible to all, private education is incredibly selective. It is common to find parents who send their kids to public school by default and are unaware of the opportunities private schools also have to offer. One of the biggest differences between public and private schools relies on the extracurricular opportunities. Our goal here is to create an easily accessible page for all parents that provides links to incredible afterschool programs in NYC.";
var isTitle = true;
function text() {
if (isTitle == true) {
  const div = document.querySelector(".card-text1");
  div.innerHTML = introParagraph;
  div.style.display = "block";
  const div2 = document.querySelector(".card-title1");
  div2.style.display = "none";
  const div3 = document.querySelector(".flip1");
  div3.style.display = "none";
  isTitle = false;
} else {
  const div = document.querySelector(".card-text1");
  div.style.display = "none";
  const div2 = document.querySelector(".card-title1");
  div2.style.display = "block";
  const div3 = document.querySelector(".flip1");
  div3.style.display = "block";
  isTitle = true;
}
}
`;

export const project = `
    <html>
        <head>
            <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
        </head>
        <body>
            ${html}
            <script type="text/javascript">
              ${js}
              ${fn}
            </script>
        </body>
    </html>
    `;
