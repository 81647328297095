import { fn } from "../api";

const html = `
  <div>
    <div class="banner">
      <h1>Good Health and Well-being</h1>
      <p class="head">
        Over 30 million Americans are not insured and cannot afford health care!
        What can you do?
      </p>
    </div>
    <div>
      <button class="button" onclick="openlink()">
        Learn more
      </button>
      <button class="button" onclick="openlink2()">
        Contact info
      </button>

      <a target="_parent" class="button" href="#section1">
        Bio
      </a>

      <a target="_parent" class="button" href="#section2">
        Statistics
      </a>

      <a target="_parent" class="button" href="#section3">
        Why is this important
      </a>

      <a target="_parent" class="button" href="#section4">
        What can you do
      </a>
    </div>

    <div>
      <div class="main" id="section1">
        <h2>Bio</h2>
        <a target="_parent" href="#section2">Next</a>

        <p>
          Around 10% of the population in the U.S do not have health insurance.
          This means that they will be charged absurd amounts of money, even for
          the most routine checkup or minor injury. Healthcare is an essential
          need, yet some people are denied healthcare, or put into debt when
          they try to recieve healthcare. One should not be stripped of their
          health just because they do not have the funds or economic fortitude
          to pay to recieve healthcare
        </p>
      </div>
      <div class="main" id="section2">
        <h2>Statistics</h2>
        <a target="_parent" href="#section3">Next</a>
        <a target="_parent" href="#section1">Back</a>
      </div>
      <div>
        <img
          class="interactive-image"
          src="https://res.cloudinary.com/moneygeek/image/upload/c_scale,q_auto:eco,f_auto,fl_lossy,w_780/v1660309214/number_of_americans_without_health_insurance_2008_2021_1_fff2bd05dd.png"
        />
      </div>
      <div class="main" id="section3">
        <h2>Why is this important</h2>
        <a target="_parent" href="#section4">Next</a>
        <a target="_parent" href="#section2">Back</a>
        <p>
          Affordable healthcare is extremly important because it has such a huge
          impact on one's life. If you need a life saving surgery, but don't
          have the money, you will be put into huge debt. Hospital services,
          even small ones, are extremly expensive, but are essential to one's
          physical well-being. In order to ensure that everyone is recieving the
          proper medical attention, it is imperitive that healthcare is made far
          more affordable and accessible for all.
        </p>
      </div>

      <div class="main" id="section4">
        <h2>What can you do?</h2>
        <a target="_parent" href="#section1">Back to the Top</a>
        <a target="_parent" href="#section3">Back</a>
        <div>
          <img
            class="interactive-image"
            src="https://www.lshtm.ac.uk/sites/default/files/SDG-HAW-graphic-850.jpg"
          />
          <p>
            By pledging an hour, you can bring awareness to this issue in
            America, and help us take steps towards making health-care more
            affordable and accessible
          </p>
        </div>
        <div class="subscribe">
          <div class="subscribe-title">
            <input type="email" placeholder="Enter your name" class="email" id="nameInput" />
            <button
              value="test"
              class="submit"
              onclick="postItem('Affordable Healthcare');submit()"
              value="Pledge an Hour"
            >
              Pledge an hour
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>`;

const css = `
.banner {
    font-family:Tahoma;
   font-size: 20px;
    color:#FFFFFF;
    background-color:SkyBlue;
    background-image: url("https://www.oecd.org/media/oecdorg/directorates/directorateforemploymentlabourandsocialaffairs/health/HCQO-Indicators.PNG");
    
    padding:10px
      
  }
  
  h1 {
    
     text-shadow: 2px 2px blue;
     text-align:center;
  }
  
  
  .button {
     display: inline-block;
    border-radius: 10px;
    background-color: white;
    border: none;
    color: #000000;
    text-align: center;
    padding: 10px;
    border-radius: 0px;
    background-color: #ffffff;
    font-family:Tahoma;
   font-size: 28px;
    padding: 5px;
    width: 200px;
    transition: all 1s;
    cursor: pointer;
    margin: 0px;
    float:left;
  
  
  }
  
  .button:hover{
    background-color:lightblue;
    color:white;
    text-decoration:underline;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  #section1 {
    margin:120px;
    margin-left:30px;
    height: 600px;
    background-color: white;
  }
  
  #section2 {
    
    background-color: white;
  }
  
  #section3 {
    margin-top:500px;
    height: 600px;
    background-color: white;
  }
  
  #section4 {
    height: 600px;
    background-color: white;
  }
  
  a {
    text-decoration:none;
    font-family: Tahoma;
    font-size: 24px;
    font-style: normal;
    font-variant: normal; 
    font-weight: 550;
    line-height: 26.4px;
    margin-left:30px;
    color:blue;
  }
  
  a:hover {
     transition: all 1s;
    cursor: pointer;
     filter:hue-rotate(70deg);
    text-decoration: underline;
  }
  
  h2 {
    color:black;
   font-family: Tahoma; 
    font-size: 24px; 
    font-style: normal;
    font-variant: normal;
    font-weight: 700; line-height: 26.4px; 
    
  }
  
  h2:hover {
     transition: all 1.5s;
    cursor: pointer;
     color:lightblue;
    text-decoration: underline;
  }
  
  
  button{ 
    font-family: Tahoma;
    font-size: 24px;
    font-style: italic;
    font-variant: normal; 
    font-weight: 550;
    line-height: 26.4px; 
    
  }
  
  
  p {
    color:black;
   font-family: Tahoma; 
    font-size: 16px; 
    font-style: normal;
    font-variant: normal;
    font-weight: 700; line-height: 26.4px; 
  }
  
  
  img {
    height:300px;
    
  }
  
  
  .subscribe {
  justify-content: center;display: flex;margin-top: 40px;
  height:50px
    }
  
  
  img.interactive-image {
      height: 300px;
      transition: height 2s, filter 2s;
  }
  
  
  img.interactive-image:hover {
      height: 320px;
    filter:hue-rotate(100deg);
    cursor:pointer;
  }
  
  .head {
    margin:0px;
    padding:10px;
    color:white;
  }
  
  p {
    font-size:20px;
  }`;

const js = `function openlink(e) {
    window.open("https://sdgs.un.org/goals/goal3","_blank")
    
    }
    
    function openlink2(e) {
    window.open("https://sdgs.un.org/about","_blank")
    
    }
     function submit() {
       
       var email= document.querySelector('.email');
       
       alert(email.value);
       
     }
    `;

export const project = `
    <html>
        <head>
            <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
        </head>
        <body>
            ${html}
            <script type="text/javascript">
              ${js}
              ${fn}
            </script>
        </body>
    </html>
    `;
