import React from "react";
import bervin1 from "./media/bervin1.png";
import bervin2 from "./media/bervin2.png";

const Videos = () => {
  const [modal, setModal] = React.useState(null);

  const handleClick = (title, videoId) => {
    setModal({ title, videoId });
  };

  const handleCloseModal = () => {
    setModal(null);
  };

  const imageContainerStyle = {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
  };

  const comingSoonStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "24px",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "rgba(0,0,0,1)",
  };

  const playButtonStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    cursor: "pointer",
  };

  const playButtonIconVideoStyle = {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fill: "white",
    width: "60px",
    height: "60px",
  };

  const playButtonIconStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fill: "white",
    width: "28px",
    height: "28px",
  };

  const modalStyle = {
    display: !!modal ? "block" : "none",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const modalContentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "60%",
    height: "60%",
    boxShadow: "2px 2px 15px black",
    border: "1px solid #54535e",
  };

  const sdgs = [
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-01.png",
      videoId: "Ds_VsNuNjLw",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-02.png",
      videoId: "B3zUanGb3Hg",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-03.png",
      videoId: "GzksFXEYinY",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-04.png",
      videoId: "MHeO5suD_Ho",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-05.png",
      videoId: "m1aKznUOeJM",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-06.png",
      videoId: "luAqIZf3gLs",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-07.png",
      videoId: "qUB5YwP7p78",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-08.png",
      videoId: "mNKYFX8n5w0",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-09.png",
      videoId: "A30RS7fGTa4",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-10.png",
      videoId: "HzOZKtNf5s4",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-11.png",
      videoId: "XshO4dYktRY",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-12.png",
      videoId: "RwZvFB75MyA",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-13.png",
      videoId: "I6A2jMy7k7c",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-14.png",
      videoId: "41MBVvM01A4",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-15.png",
      videoId: "6jszxRChGWs",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-16.png",
      videoId: "mkyZ2Iw6ZkY",
    },
    {
      imageUrl:
        "https://www.un.org/sustainabledevelopment/wp-content/uploads/2018/05/E_SDG-goals_icons-individual-rgb-17.png",
      videoId: "9BCSBdhoV2M",
    },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="transform transition-transform duration-300 scale-125 hover:scale-150 mb-12"
          onClick={() => handleClick("Intro to the SDGs", "lOhWFI7t-u0")}
        >
          <img
            className="col-span-1 mb-12 max-h-24 w-full object-contain lg:col-span-1"
            src={bervin1}
            alt={`SDG Intro Video`}
            style={imageContainerStyle}
          />
          <div style={playButtonStyle}>
            <svg
              style={playButtonIconVideoStyle}
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
              ></path>
            </svg>
          </div>
        </div>
        <div
          className="transform transition-transform duration-300 scale-125 hover:scale-150 mb-12 ml-36"
          onClick={() => handleClick("SDG Contest", "OKJ66KrReDs")}
        >
          <img
            className="col-span-1 mb-12 max-h-24 w-full object-contain lg:col-span-1"
            src={bervin2}
            alt={`SDG Intro Video`}
            style={imageContainerStyle}
          />
          <div style={playButtonStyle}>
            <svg
              style={playButtonIconVideoStyle}
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="mx-auto col-span-5 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        {sdgs.map(({ imageUrl, title, videoId }, id) => (
          <div
            className="transform transition-transform duration-300 hover:scale-125"
            onClick={() => handleClick(title, videoId)}
            style={imageContainerStyle}
          >
            <img
              className="col-span-1 max-h-24 w-full object-contain lg:col-span-1"
              src={imageUrl}
              alt={`SDG Goal ${id + 1}`}
            />
            <div style={playButtonStyle}>
              <svg
                style={playButtonIconStyle}
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                ></path>
              </svg>
            </div>
          </div>
        ))}
        {!!modal && (
          <div style={modalStyle} onClick={handleCloseModal}>
            {!!modal.videoId ? (
              <iframe
                className="absolute top-1/2 left-1/2 bg-black w-full md:w-3/5 h-3/5 border border-[#54535e] shadow-custom"
                style={{
                  transform: "translate(-50%, -50%)",
                }}
                title="YouTube video player"
                src={`https://www.youtube.com/embed/${modal.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 1)",
                  ...modalContentStyle,
                }}
              >
                <h2 style={comingSoonStyle}>Video Coming Soon</h2>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Videos;
