import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import projects from "./projects";

const StudentSite = () => {
  const { id } = useParams();

  // look for ID in iframe
  const iframe = document.getElementById("student-site");

  useLayoutEffect(() => {
    if (!iframe) return;
    // if there is an anchor hash, scroll to it

    iframe.contentDocument.body.addEventListener("click", (e) => {
      const href = e.target?.href;

      if (!href) return;

      const anchorHash = e.target?.href.split("#")[1];
      if (!anchorHash) return;

      const anchor = iframe.contentWindow.document.querySelector(
        `#${anchorHash}`
      );
      anchor.scrollIntoView();
    });
  }, [iframe]);

  const src = projects[id].src;

  return <iframe id="student-site" title="student-site" srcDoc={src} />;
};

export default StudentSite;
