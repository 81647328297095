import { fn } from "../api";

const html = `<div id="output">
<div class="">

  <div class="container">
    <div class="hero">
      <p class="top-left-text">Good Health and Well Being</p>
      <p class="top-right-text"> Home/Learn/About
      <div class="hero-infographic">
        <div class="hero-number">
          <h3 class="hero-number-header">95%</h3>
          <p class="hero-info">Of people suffer from health problems</p>
        </div>
      </div>
    </div>
  </div>
  <div class="cards">
    <div class="card">
      <h4 class="card-title">Sustainable Development Goal 3</h4>
      <p class="card-text">Good Health and Well Being</p>
      <p class="card-text"> "To ensure healthy lives and promote well-being for all at all ages."</p>
    </div>
    <div class="card">
      <h4 class="card-title">Targets (benefits)</h4>
      <p class="card-text"> 1) Is the 3rd goal of the 17 Sustainable Development Goals established by the United Nations in 2015.</p>
      <p class="card-text"> 2) In 2020, the percentage of people who perceived their health as good or very good was highest in cities 71%.</p>
      <p class="card-text"> 3) They are 5 types of food categories: Dairy, Vegetable, Fruits, Grain, and Protein</p>
    </div>
    <div class="card">
      <h4 class="card-title">Impacts (Problems)</h4>
      <p class="card-text"> 1) 2.3 billion of people were founded to have more than 5 condition</p>
      <p class="card-text"> 2) The people that has poor health receive airborne diseases such as tuberculosis and respiratory infections such as pneumonia.</p>
      <p class="card-text"> 3) Some food makes you be overweight or put you obese. </p>
      <p class="card-text"> 4) The major nutritional problems are protein energy mal-nutrition, Vitamin A deficiency, iron deficiency (anemia) and iodine disorders.</p>
    </div>
    <div class="card">
      <h4 class="card-title">Reasoning</h4>
      <p class="card-text"> 1) This goal is important because it takes care of your body and helps you prevents diseases on yourself and other people.</p>
    </div>
  </div>
  <div class="learn-more">
    <button value="test" class="submit" onclick="popup_learn_more()">Learn More</button>
  </div>
  <div class="subscribe">

    <div class="subscribe-title"> <input type="text" placeholder="Enter your email" class="email"></div>

  </div>
</div>
</div>

<!-- The Modal -->
<div id="myModal" class="modal">

<!-- Modal content -->
<div class="modal-content">
  <span onclick="close_modal()" class="close">&times;</span>
  <p> Targets:
  <p> 4) Another good benefit about Good Health and well being is that doing exercise is great because it helps you maintain your weight.
  <p> Impacts:
  <p> 5) Another problem is that some people eat foods without looking the caloris and without knowing what is it.
  <p> Reasoning:
  <p> 2) Another reason that this goal is important is because when we do exercise and eat less calories that helps you maintain your body and not have problem with your body.
</div>

</div>`;

const css = `.cards {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.submit {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
 
}

.learn-more {
   display: flex;
  justify-content: center;
  
}

.hero {
  font-family: Trebuchet MS, sans-serif;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.hero {
  font-size: 20px;
  margin-bottom: 20px;
}
.hero {
  font-size: 80px;
}
.hero-infographic {
  font-size: 15px;
  color: white;  
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.hero-number-header{
  Font-size: 10rem;
  text-align: center;
  margin-bottom: 25px;
  filter: brightness(100%);
}

.hero-info{
  font-size: 35px
}

.card {
  box-shadow: 10px 10px 10px #02830B;
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  text-align: right;
  margin-bottom: 40px;
}
.hero {
  font-size: 20px;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
.hero {
  background: 
 linear-gradient(rgb(0,0,0,0.4), rgb(0,0,0,0.6)),   url('https://www.ucsfhealth.org/-/media/project/ucsf/ucsf-health/education/hero/top-ten-foods-for-health-2x.jpg?h=1112&w=2880&hash=185E4819695062279DF393FBFCB6BEC7');
  
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 300px 0;
  height: 200px;
  width: 100vw;
  filter: brightness(100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.container {
  display: flex;
  justify-content: center;
  background-color: green;

}

.top-left-text {
  position: absolute;
  top:0;
  left:20px;
  color: white;
  font-size: 30px;
  text-decoration: underline;
}

.top-right-text{
  position: absolute;
  top:0;
  right:20px;
  color: White;
  font-size: 30px;
}
.subscribe-title {
   display: flex;
  justify-content: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.subscribe-title{
  padding: 10px;
}
`;

const js = `function popup_learn_more(){
  
  
  // Get the modal
  var modal = document.getElementById("myModal");
    
    modal.style.display = "block";
  }
  
  function close_modal() {
    
  // Get the modal
  var modal = document.getElementById("myModal");  
    modal.style.display = "none"
  }`;

export const project = `
<html>
    <head>
        <style>body { margin: 0; padding: 0; box-sizing: border-box; } ${css}</style>
    </head>
    <body>
        ${html}
        <script type="text/javascript">
          ${js}
          ${fn}
        </script>
    </body>
</html>
`;
